/* slightly transparent fallback */
#frosted {
  background: radial-gradient(68.13% 100% at 100% 0, #dc5ce567 0, rgba(221, 92, 229, 0) 100%), linear-gradient(95.52deg, #2e94d36b, rgba(46, 149, 211, 0)), linear-gradient(0deg, #5436da6c, #5436da67), rgba(100, 50, 180, 0.836)
}

/* if backdrop support: very transparent and blurred */
@media screen and (min-width: 800px) {
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  #frosted {
    background: radial-gradient(68.13% 100% at 100% 0, #dc5ce55d 0, rgba(221, 92, 229, 0) 100%), linear-gradient(95.52deg, #2e94d35e, rgba(46, 149, 211, 0)), linear-gradient(0deg, #5436da60, #5436da7a), rgba(100, 50, 180, .6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}
}

#transluscent {
  background-color: rgba(255, 255, 255, 0.15)
}

.MuiTypography-root {
  text-shadow: 0px 0px 2em #00000033

}