.appBar {
    z-index: 9
}

.toolbar {
    width: 100%
}

.avatar {
    border: 2px solid var(--color-secondary);
    margin: var(--spacing);
    width: calc(6 * var(--spacing));
    height: calc(6 * var(--spacing));
}

.popover {
    width: calc(40 * var(--spacing))
}