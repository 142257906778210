.animate {
  opacity: 1;
  transition: opacity 0.1s linear;
  -webkit-transition: opacity 0.1s linear;
  will-change: opacity;
}

.animatetopointthree {
  opacity: 0.3;
  transition: opacity 0.1s linear;
  -webkit-transition: opacity 0.1s linear;
  will-change: opacity;
}

img {
  opacity: 0;
  will-change: opacity;
}

.img-gradient {
  position: relative;
  display: inline-block;
}

/* 
  #002f4b,#dc4225 
  Convert HEX to RGBA - http://hex2rgba.devoth.com/
  */
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    transparent 90%,
    rgb(103, 58, 183) 100%
  );
}

.tinted:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(204, 0, 255, 0.7);
}
